import React, { ReactElement } from 'react';
import Gallery from '../components/Gallery';
import Layout from '../components/Layout';

function Index(): ReactElement {
  return (
    <Layout>
      <Gallery />
    </Layout>
  );
}

export default Index;
