import React, { ReactNode } from 'react';
import { PhotoSwipeGallery, PhotoSwipeGalleryItem } from 'react-photoswipe';

import 'react-photoswipe/lib/photoswipe.css';

const getThumbnailContent = (item: PhotoSwipeGalleryItem): ReactNode => {
  return <img className="min-w-full" src={item.thumbnail} alt="Certificate" />;
};

const Gallery: React.FunctionComponent<{}> = () => {
  const items: PhotoSwipeGalleryItem[] = Array.from(
    [1, 2, 3],
    (num: Number): PhotoSwipeGalleryItem => ({
      src: `image_${num}.jpg`,
      thumbnail: `image_${num}.jpg`,
      w: 1200,
      h: 900,
    })
  );

  return (
    <div className="flex justify-center flex-grow flex-col">
      <div className="flex flex-row justify-center">
        <PhotoSwipeGallery
          items={items}
          className="container"
          thumbnailContent={getThumbnailContent}
        />
      </div>
    </div>
  );
};

export default Gallery;
